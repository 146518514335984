import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import closeIcon from '../../assets/images/close-24px.svg'
Modal.setAppElement(`#___gatsby`);
const modalStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.58)",
        zIndex: 4
    },
    content: {
        position: "relative",
        top: "auto",
        left: "auto",
        right: "auto",
        bottom: "auto",
        maxWidth: "960px",
        margin: "32px auto",
        padding: 0,
        border: 0,
        background: '#48c0fb',
    },
};

const PaymentModal = ({ status, formId }) => {
    const [modalOpen, setModalOpen] = useState(true);
    const modalCloseTimeout = 300;
    const closeModal = () => {
        setModalOpen(false);
        window.location.reload()
    };


    useEffect(() => {
        // var b = document.createElement("script");
        // b.innerHTML = "function SmartCURRENCYOLXHook() {OSREC.CurrencyFormatter.locales.de.p = '!#,##0.00';SmartCURRENCYOLXSettings.disableTypingTimer = true;}"
        // document.getElementsByTagName('body')[0].appendChild(b)

        // var p = document.createElement("script");
        // p.innerHTML = 'var SmartCURRENCYOLXSettings = { "product": "SmartCURRENCYOLX", "prompt": "I want the charity to receive this full amount (increase my payment to cover the transaction fee)", "summary": "Our online payments are processed in [olxcurrency]. Your donation will be [olxcurrency] [olxorig] which is the equivalent of [currency] [orig].", "summarywithfee": "Our online payments are processed in [olxcurrency]. Your donation will be [olxcurrency] [olxamount] which is the equivalent of [currency] [amount]. This includes the payment transaction fee of [currency] [fee].", "summarysamecurrencywithfee": "You will pay [amount] which includes the transaction fee of [fee].", "intro": "Donation amounts are being shown in", "defaultCurrency": "GB", "showDDOnlyForCountry": "United Kingdom", "flatFeeMode": false, "variable": 2.75, "fixed": 0.19, "autohidegiftaid": true, "client": "aamer@peacetrain.org" }'
        // document.getElementsByTagName('body')[0].appendChild(p);

        // var o = document.createElement("script");
        // o.innerHTML = 'var SmartZIPOLXSettings = { "pcaKey": "DX44-BF36-WD16-RE75", "findAddressPrompt": "Find my address", "manualPrompt": "Add address manually", "product": "SmartZIPOLX", "client": "aamer@peacetrain.org" }'
        // document.getElementsByTagName('body')[0].appendChild(o);

        // var j = document.createElement("script");
        // j.src = "https://www.smartthing2.com/download/file.php?jsformat=1&olx=1&f=-js.html&c=aamer@peacetrain.org&k=NTdlOGRhZDJhYW1"
        // document.getElementsByTagName('body')[0].appendChild(j);

        // var t = document.createElement("script");
        // t.src = "https://www.smartthing2.com/download/file.php?jsformat=1&olx=1&f=-js.html&c=aamer@peacetrain.org&k=Yjc2OTgwNzJhYW1"
        // document.getElementsByTagName('body')[0].appendChild(t);

        var e = document.createElement("script");
        e.src = "https://bbox.blackbaudhosting.com/webforms/bbox-min.js"
        e.async = true
        document.getElementsByTagName('head')[0].appendChild(e);

        window.bboxInit = function () {
            window.bbox.showForm(formId);
        }

        if (status) {
            setModalOpen(true)
        }
    }, [status, formId])
    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            style={modalStyles}
            contentLabel="Modal"
            closeTimeoutMS={modalCloseTimeout}
        >
            <div>
                <Link
                    to="/"
                    aria-label="close modal"
                    style={{
                        width: "39px",
                        height: "39px",
                        position: "absolute",
                        right: 0,
                        top: 0,
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        closeModal();
                    }}
                >
                    <img src={closeIcon} alt="closeIcon" />
                </Link>
                <div id="bbox-root"></div>
            </div>
        </Modal>
    )
}

export default PaymentModal;