import { Link } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"

const SubProjects = ({ sources }) => {
    const find = '<img src="';
    const re = new RegExp(find, 'g');
    const doc = new RegExp('<a href="\/', 'g')

    return (
        sources.map((subProject) => (
            <div>
                <h4><strong>{subProject.Header}</strong></h4>
                <ReactMarkdown
                    source={subProject.Content.replace(re, `<img src="${process.env.API_URL}`).replace(doc, `<a href="${process.env.API_URL}/`)}
                    escapeHtml={false}
                    className="ck-content"
                />
                {subProject.ProjectButtons && subProject.ProjectButtons.map((button) => (
                    <div>
                        {button.ExternalLink ?
                            <a href={button.Document ? button.Document.localFile.publicURL : button.Url} target={button.NewTab ? "_blank" : "_self"} className="btn btn-primary">
                                {button.Text}
                            </a>
                            :
                            <Link to={button.Document ? button.Document.localFile.publicURL : button.Url} target={button.NewTab ? "_blank" : "_self"} className="btn btn-primary">
                                {button.Text}
                            </Link>
                        }
                    </div>
                ))}
            </div>
        ))
    )
}

export default SubProjects
