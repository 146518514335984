import React, { useState } from "react"
import MainLayout from '../layouts/MainLayout'
import FirstLevelBanner from '../components/our-work/first-level/Banner'
import OurWorkComponent from '../components/OurWorkComponent'
import Media from "../components/our-work/first-level/Media"
import { Link, graphql } from "gatsby"
import SEO from "../components/SEO"
import heart from "../assets/images/pt-heart-icon.svg"
import PaymentModal from '../components/modals/PaymentModal'
import SubProjects from "../components/our-work/first-level/SubProjects"
import zakatEligibleImg from "../assets/images/zakat-yes.svg"
import zakatNotEligibleImg from "../assets/images/zakat-no.svg"

export default function FirstLevelPage({ data }) {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <MainLayout>
            <SEO title={`${data.strapiProjects.seo.title}`} description={`${data.strapiProjects.seo.description}`} />
            <FirstLevelBanner sources={data.strapiProjects.HeroImage} />
            {modalOpen && <PaymentModal status={true} formId={data.strapiProjects.formId} />}
            <section className="container">
                <h1 className="page-title-alt">{data.strapiProjects.Header}</h1>

                <div className="work-container">
                    <div className="work-purpose">
                        <p>{data.strapiProjects.sdgHeader}</p>
                        <p>
                            {data.strapiProjects.sdgContent}
                        </p>
                        <img src={data.strapiProjects.Icon.localFile.publicURL} alt={data.strapiProjects.sdgHeader} />
                        {data && data.strapiProjects.DonationStatus && data.strapiProjects.formId &&
                            <div className="donation-box">
                                <p>If you would like to</p>
                                <button onClick={() => { setModalOpen(true) }} className="btn btn-primary btn-icon">
                                    contribute
                                    <img src={heart} alt="heart-icon" />
                                </button>
                                {data.strapiProjects.zakatEligible ? <p><img src={zakatEligibleImg} alt="Zakat Eligible" /></p> : <p><img src={zakatNotEligibleImg} alt="Zakat Not Eligible" /></p>}
                            </div>
                        }
                    </div>
                    <div className="work-content">
                        {
                            data.strapiProjects.SubProjects && data.strapiProjects.SubProjects.length > 0 && <SubProjects sources={data.strapiProjects.SubProjects} />
                        }
                    </div>
                </div>
                <Media sources={data.strapiProjects.pictures} />
            </section>
            <OurWorkComponent />
        </MainLayout>
    )
}

export const pageQuery = graphql`  
    query($slug: String!) {
        strapiProjects(Slug: {eq: $slug}) {
            formId
            sdgContent
            sdgHeader
            DonationStatus
            zakatEligible
            Header
            Icon {
                localFile{
                    publicURL
                }
            }
            HeroImage {
                desktopImage {
                    localFile{
                        publicURL
                    }
                }
                mobileImage {
                    localFile{
                        publicURL
                    }
                }
            }
            pictures {
                id
                width
                height
                alternativeText
                url
            }
            seo {
                title
                description
                keywords
            }
            SubProjects {
                Content
                Header
                ProjectButtons {
                    Text
                    NewTab
                    ExternalLink
                    Url
                    Document {
                        localFile{
                            publicURL
                        }
                }
            }
        }
    }
  }
`