import React from "react"
import { chunk } from "lodash-es"

const Media = ({ sources }) => {
  const [firstSourceSet, secondSourceSet] = chunk(sources, 2)

  return (
    <section className="soundingLouder-firstLevel-media">
      <div className="media-sources">
        {firstSourceSet.map(source => (
          <div className="outer">
            <div className="inner">
              <img
                key={source.id}
                src={`${process.env.API_URL}${source.url}`}
                alt={source.alternativeText}
              />
              <span>{source.alternativeText}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="media-sources">
        {secondSourceSet.map(source => (
          <div className="outer">
            <div className="inner">
              <img
                key={source.id}
                src={`${process.env.API_URL}${source.url}`}
                alt={source.alternativeText}
              />
              <span>{source.alternativeText}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Media
